export function geraStringAleatoria(tamanho) {
  var stringAleatoria = "";
  var caracteres = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  for (var i = 0; i < tamanho; i++) {
    stringAleatoria += caracteres.charAt(
      Math.floor(Math.random() * caracteres.length)
    );
  }
  return stringAleatoria;
}
