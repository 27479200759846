<template>
  <div class="tw-mx-5">
    <div class="tw-pb-5 tw-pl-5 tw-pt-6 tw--mx-5 tw--mt-5 tw-text-left tw-flex">
      <v-select
        class="tw-max-w-xs"
        outlined
        dense
        label="Tipo de moldura"
        :items="listaTipos"
        item-text="name"
        item-value="id"
        v-model="filtroTipo"
        hide-details
      ></v-select>
      <div class="tw-pl-3">
        <v-checkbox
          label="Ativos"
          v-model="filtroAtivo"
          dense
          hide-details
        ></v-checkbox>
      </div>
    </div>
    <div class="tw-flex tw-flex-wrap tw-justify-start tw-items-center tw-p-2">
      <vItemList
        @updateList="updateMolduras()"
        v-for="item in filteredList"
        :key="item.id"
        :item="item"
      />
    </div>
  </div>
</template>

<script>
import { collection, getDocs } from "@firebase/firestore";
import { getDownloadURL, ref } from "@firebase/storage";
import { db, storageMolduras } from "@/service/firebase";
import vItemList from "./ItemListMoldura";
import { getList } from "@/constants/types/photos.js";

export default {
  components: {
    vItemList,
  },
  data() {
    return {
      loadList: true,
      filtroAtivo: true,
      filtroTipo: "polaroid",
      listaTipos: [],
      listaDeMolduras: [],
    };
  },
  computed: {
    filteredList() {
      return this.listaDeMolduras.filter((item) => {
        let itemTipo = item.dados.tipo;
        let itemAtivo = item.dados.ativo;
        let filtroTipo = this.filtroTipo;
        let filtroAtivo = this.filtroAtivo;
        return itemTipo.match(filtroTipo) && itemAtivo == filtroAtivo;
      });
    },
  },
  beforeMount() {
    this.listaTipos.push({ name: "Todos", id: "" });

    let items = getList();
    items.forEach((item) => {
      this.listaTipos.push(item);
    });
  },
  mounted() {
    this.getMolduras();
    let self = this;
    this.$root.$on("updateListMolduras", () => {
      self.updateMolduras();
    });
  },
  beforeDestroy() {
    this.$root.$off("updateListMolduras");
  },
  methods: {
    linkagemDeImagemUrl(item) {
      let newItem = item;
      let filename = item.dados.referenciaStorage;
      let refStorage = ref(storageMolduras, filename);
      getDownloadURL(refStorage).then((link) => {
        newItem.dados.url = link;
        this.listaDeMolduras.push({ ...newItem });
      });
    },
    async updateMolduras() {
      this.listaDeMolduras = [];
      let referencia = collection(db, "/molduras");
      let moldurasList = await getDocs(referencia);
      moldurasList.forEach((snap) => {
        let payload = {
          dados: snap.data(),
          id: snap.id,
        };
        this.linkagemDeImagemUrl(payload);
      });
    },
    async getMolduras() {
      let referencia = collection(db, "/molduras");
      const moldurasList = await getDocs(referencia);
      moldurasList.forEach((snap) => {
        let payload = {
          dados: snap.data(),
          id: snap.id,
        };
        this.linkagemDeImagemUrl(payload);
      });
    },
  },
};
</script>

<style></style>
