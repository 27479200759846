<template>
  <div class="customCard">
    <div>
      <img
        :src="item.dados.url"
        class="tw-mx-auto"
        :class="{
          viewNormal: item.dados.tipo == 'normal',
          viewPolaroid: item.dados.tipo == 'polaroid',
        }"
      />
    </div>
    <div class="containContext">
      <div class="containLabel">
        <div class="labelIcon">
          <v-icon size="18" color="rgba(80,80,80,0.5)">mdi-text</v-icon>
        </div>
        <div class="labelText">
          {{ item.dados.descricao | hasData }}
        </div>
      </div>

      <div class="containLabel">
        <div class="labelIcon">
          <v-icon size="18" color="rgba(80,80,80,0.5)">mdi-tag-outline</v-icon>
        </div>
        <div class="labelText tw-normal-case">
          {{ item.dados.tipo | parseType }}
        </div>
      </div>
      <div class="containLabel">
        <div class="labelIcon">
          <v-icon size="18" color="rgba(80,80,80,0.5)"
            >mdi-clock-outline</v-icon
          >
        </div>
        <div class="labelText">
          {{ dataParse }}
        </div>
      </div>
      <div class="tw-grid tw-grid-cols-2 tw-pt-2">
        <div>
          <button
            class="tw-flex tw-items-center tw-justify-center tw-py-2 tw-rounded tw-px-3 tw-bg-red-600 hover:tw-bg-opacity-90 tw-text-white"
            @click="removerItem()"
          >
            <div class="tw-flex-none tw-pr-1">
              <v-icon color="white" size="16">mdi-trash-can</v-icon>
            </div>
            <div class="tw-flex-1 tw-pl-1 tw-pt-1 tw-text-sm">Remover</div>
          </button>
        </div>
        <div class="tw-text-right">
          <button
            v-if="item.dados.ativo"
            class="btnLockUnlock"
            @click="lockItem()"
          >
            <div class="tw-flex-none tw-pr-1">
              <v-icon color="white" size="16">mdi-lock</v-icon>
            </div>
            <div class="tw-flex-1 tw-pl-1 tw-pt-1 tw-text-sm">Bloquear</div>
          </button>
          <button v-else class="btnLockUnlock" @click="unlockItem()">
            <div class="tw-flex-none tw-pr-1">
              <v-icon color="white" size="16">mdi-lock-open-variant</v-icon>
            </div>
            <div class="tw-flex-1 tw-pl-1 tw-pt-1 tw-text-sm">Desbloquear</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { deleteDoc, doc, setDoc } from "@firebase/firestore";
import { ref, deleteObject } from "@firebase/storage";
import { db, storageMolduras } from "@/service/firebase";
import { getById } from "@/constants/types/photos.js";

export default {
  name: "ItemListMoldura",
  props: ["item"],
  data() {
    return {
      imagemRender: null,
    };
  },
  filters: {
    parseType: (val) => {
      let item = getById(val);
      return item.name;
    },
    hasData(val) {
      if (val == null || val == "") {
        return "s/registro";
      } else {
        return val;
      }
    },
  },
  computed: {
    dataParse() {
      let isTimer = this.item.dados.timestamp;
      let dataObject = new Date(
        isTimer.seconds * 1000 + isTimer.nanoseconds / 1000000
      );
      return moment(dataObject.toDateString()).format("DD/MM/YYYY hh:mm:ss");
    },
  },
  methods: {
    async _setFireLock(value) {
      if (value == undefined) {
        console.error(">>> erro");
        this.$root.errorModal("errorModal");
        return false;
      }
      const itemID = this.item.id;
      const firestoreRef = doc(db, "molduras", itemID);
      await setDoc(
        firestoreRef,
        {
          ativo: value,
        },
        { merge: true }
      );
      this.$emit("updateList");
    },
    unlockItem() {
      this._setFireLock(true);
    },
    lockItem() {
      this._setFireLock(false);
    },
    async removerItemFirestore() {
      let self = this;
      const itemID = this.item.id;
      const firestoreRef = doc(db, "molduras", itemID);

      await deleteDoc(firestoreRef)
        .then(() => {
          self.$root.sucessoModal("Removido com sucesso");
          self.$emit("updateList");
        })
        .catch((err) => {
          self.$root.errorModal("Ocorreu um erro durante a remoção");
          console.error(err);
        });
    },
    async removerItem() {
      let self = this;
      const childImage = this.item.dados.referenciaStorage;
      const storageReference = ref(storageMolduras, childImage);

      await deleteObject(storageReference)
        .then(() => {
          self.removerItemFirestore();
        })
        .catch((err) => {
          self.$root.errorModal("Ocorreu um erro durante a exclusão");
          console.error(err);
          self.removerItemFirestore();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btnLockUnlock {
  @apply tw-flex tw-items-center tw-justify-center tw-py-2 tw-rounded tw-px-3 tw-bg-gray-500 hover:tw-bg-opacity-90 tw-text-white tw-mx-auto;
}
.containContext {
  @apply tw-pt-3 tw-border-t-2 tw-border-gray-100 tw-mt-3;
}
.containLabel {
  @apply tw-p-1 tw-flex tw-justify-center tw-items-center;
}
.labelIcon {
  @apply tw-flex-none tw-px-1;
}
.labelText {
  @apply tw-text-sm tw-flex-1 tw-font-normal tw-px-1 tw-inline-block tw-capitalize tw-text-gray-700;
}
.customCard {
  //tw-w-full sm:tw-w-auto tw-max-w-xl sm:tw-max-w-sm
  width: 277px;
  @apply tw-bg-white tw-shadow tw-m-1 tw-rounded tw-p-3 tw-inline-block;
}
.viewPolaroid {
  width: calc(500px / 3) !important;
  min-width: calc(500px / 3) !important;
  max-width: calc(500px / 3) !important;
  height: calc(600px / 3) !important;
  min-height: calc(600px / 3) !important;
  max-height: calc(600px / 3) !important;
}
.viewNormal {
  width: calc(646.5px / 3) !important;
  min-width: calc(646.5px / 3) !important;
  max-width: calc(646.5px / 3) !important;
  height: calc(433.5px / 3) !important;
  min-height: calc(433.5px / 3) !important;
  max-height: calc(433.5px / 3) !important;
}
</style>
