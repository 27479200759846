<template>
  <div>
    <div class="tituloContent sm:tw-text-left tw-text-center">Molduras</div>
    <div id="menu" class="containNav">
      <button
        class="btnNav"
        :class="{ active: mount == 'lista' }"
        @click="mount = 'lista'"
      >
        Lista de Molduras
      </button>
      <button
        class="btnNav"
        :class="{ active: mount == 'novo' }"
        @click="mount = 'novo'"
      >
        Nova Moldura
      </button>
    </div>
    <vNovaMoldura v-show="mount === 'novo'" />
    <vListaDeMolduras v-show="mount === 'lista'" />
  </div>
</template>

<script>
import vListaDeMolduras from "./ListaDeMolduras.vue";
import vNovaMoldura from "./NovaMoldura.vue";

export default {
  components: {
    vListaDeMolduras,
    vNovaMoldura,
  },
  data() {
    return {
      mount: "lista",
    };
  },
};
</script>

<style lang="scss" scoped>
.containNav {
  @apply tw-px-4 tw-flex tw-flex-row tw-mb-5 tw-bg-gray-100;
}
.btnNav {
  @apply tw-p-4 tw-mx-2 tw-border-b tw-border-solid tw-text-gray-700 tw-transition-all tw-duration-300;
  &.active {
    @apply tw-border-pink-700;
  }
  :not(.active) {
    @apply tw-border-gray-700;
  }
}
</style>
