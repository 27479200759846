<template>
  <div class="tw-mx-0 sm:tw-mx-5">
    <div
      class="tw-mt-5 tw-bg-white tw-p-2 sm:tw-p-5 tw-mb-2 tw-mx-auto tw-shadow tw-rounded tw-max-w-2xl"
    >
      <div id="forms">
        <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2">
          <div class="tw-p-2">
            <div
              class="tw-my-2 tw-font-semibold tw-text-sm tw-text-gray-700 tw-uppercase"
            >
              Descrição
            </div>
            <input class="input" type="text" v-model="include.descricao" />
          </div>
          <div class="tw-p-2">
            <div
              class="tw-my-2 tw-font-semibold tw-text-sm tw-text-gray-700 tw-uppercase"
            >
              tipo de foto
            </div>
            <select class="input" v-model="include.tipo">
              <option
                :key="index"
                v-for="(tipo, index) in tiposDeFoto"
                :value="tipo.id"
              >
                {{ tipo.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="tw-p-2">
          <div
            class="tw-my-2 tw-font-semibold tw-text-sm tw-text-gray-700 tw-uppercase"
          >
            Seleciona a imagem
          </div>
          <input
            class="input"
            id="inputFileUpload"
            type="file"
            accept="jpg,jpeg,png"
            ref="fileUpdate"
            @change="previewFile"
            :disabled="loadingInclude"
          />
          <div
            class="tw-p-3 tw-text-gray-600 textOtimize tw-bg-gray-100 tw-rounded tw-my-2"
          >
            <div v-if="include.tipo == 'normal'">
              Medida (técnica) recomendada para a foto normal: (646.5 x 433.5)
            </div>
            <div v-if="include.tipo == 'polaroid'">
              Medida (técnica) recomendada para a foto polaroid, (500 x 600)
            </div>
          </div>
        </div>
        <div class="tw-p-2 tw-grid tw-grid-cols-2">
          <div class="tw-text-left">
            <button
              class="tw-p-2 tw-px-4 tw-bg-gray-200 tw-text-gray-700 tw-rounded tw-items-center"
              @click="togglePreview = !togglePreview"
            >
              <v-icon color="rgba(55, 65, 81,1)" v-if="togglePreview == false"
                >mdi-eye</v-icon
              >
              <v-icon color="rgba(55, 65, 81,1)" v-if="togglePreview == true"
                >mdi-eye-off</v-icon
              >
              <span class="tw-pl-2">Preview foto</span>
            </button>
          </div>
          <div class="tw-text-right">
            <button
              class="tw-text-white tw-p-2 tw-px-4 tw-rounded tw-bg-primary disabled:tw-bg-gray-200 disabled:tw-text-gray-400"
              @click="initUploadImage()"
              :disable="!selectedFile || loadingInclude"
            >
              <div v-if="!loadingInclude">Registrar</div>
              <div v-else class="tw-px-5">
                <v-icon class="tw-animate-spin" color="white"
                  >mdi-loading</v-icon
                >
              </div>
            </button>
          </div>
        </div>
      </div>

      <div v-show="togglePreview" id="image" class="tw-text-center">
        <div class="tw-text-gray-700 tw-font-normal tw-py-2">
          Preview Moldura
        </div>
        <div
          class="tw-border-b-2 tw-border-solid tw-w-7 tw-mx-auto tw-mb-3"
        ></div>
        <div class="tw-overflow-auto">
          <img
            id="previewImage"
            class="configView tw-mx-auto tw-border-0 tw-border-none"
            :class="{
              viewPolaroid: include.tipo == 'polaroid',
              viewNormal: include.tipo == 'normal',
            }"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addDoc, collection, Timestamp } from "@firebase/firestore";
import { db, storageMolduras } from "@/service/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { geraStringAleatoria } from "@/tools.js";
import { getList } from "@/constants/types/photos.js";

export default {
  data() {
    return {
      loadingInclude: false,
      togglePreview: false,
      selectedFile: null,
      mediaSize: {
        polaroid: { width: "500px", height: "600px" },
        normal: { width: "646.5px", height: "433.5px" },
      },
      tiposDeFoto: [],
      include: {
        ativo: true,
        descricao: "",
        timestamp: null,
        tipo: "polaroid",
        referenciaStorage: null,
      },
    };
  },
  beforeMount() {
    this.tiposDeFoto = getList();
  },
  methods: {
    previewFile() {
      var preview = document.querySelector("#previewImage");
      var file = document.querySelector("#inputFileUpload").files[0];
      this.selectedFile = file;
      var reader = new FileReader();
      reader.onloadend = function () {
        preview.src = reader.result;
      };
      if (file) {
        reader.readAsDataURL(file);
      } else {
        preview.src = "";
      }
    },
    initUploadImage() {
      let valid = this.validacoes();
      if (!valid.status) {
        this.$root.infoModal(valid.message);
        return false;
      }
      this.loadingInclude = true;
      this.registrarFirestore();
    },
    clearForm() {
      document.querySelector("#previewImage").src = "";
      document.querySelector("#inputFileUpload").value = "";
      this.selectedFile = null;
      this.include = {
        descricao: "",
        timestamp: null,
        tipo: "polaroid",
        referenciaStorage: null,
        ativo: true,
      };
    },
    createReferenceStorage() {
      let tipo = this.include.tipo.toLocaleLowerCase();
      let genString = geraStringAleatoria(12);
      this.include.referenciaStorage = `${tipo}/${genString}`;
    },
    async registrarStorage() {
      let self = this;
      let fileInclude = this.$refs.fileUpdate.files[0];
      let referencia = this.include.referenciaStorage;
      let storageRef = ref(storageMolduras, referencia);
      uploadBytes(storageRef, fileInclude).then(() => {
        self.loadingInclude = false;
        self.clearForm();
        self.$root.$emit("updateListMolduras");
        self.$root.sucessoModal("Registrado com sucesso!");
      });
    },
    async checkIfFileExists(storageRef) {
      return new Promise((resolve, reject) => {
        getDownloadURL(storageRef)
          .then(() => {
            return resolve();
          })
          .catch((error) => {
            if (error.code === "storage/object-not-found") {
              return reject();
            } else {
              return reject();
            }
          });
      });
    },
    validacoes() {
      if (this.selectedFile == null) {
        return {
          message: "Você deve selecionar uma foto antes",
          status: false,
        };
      } else if (
        this.include.descricao == null ||
        this.include.descricao.length <= 2
      ) {
        return {
          message: "Descricao muito curta.",
          status: false,
        };
      } else {
        return {
          message: "ok",
          status: true,
        };
      }
    },
    async registrarFirestore() {
      let self = this;
      this.createReferenceStorage();
      let storageRef = ref(
        storageMolduras,
        `${this.include.referenciaStorage}`
      );

      this.checkIfFileExists(storageRef).then(() => {
        console.log("Já existe esse codigo no banco, criando outro...");
        this.registrarFirestore();
        return false;
      });

      this.include.timestamp = Timestamp.fromDate(new Date());

      let referenciaInclude = collection(db, "/molduras");
      let payloadDoc = { ...this.include };
      await addDoc(referenciaInclude, payloadDoc)
        .then(() => {
          self.registrarStorage();
        })
        .catch((err) => {
          self.$root.errorModal(
            "Ocorreu um erro durante o processo, tente novamente."
          );
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.textOtimize {
  text-rendering: optimizeLegibility;
}
.configView {
  border: 2px solid gray;
  user-select: none;
  padding: 5px;
}
.viewPolaroid {
  width: 500px !important;
  min-width: 500px !important;
  max-width: 500px !important;
  height: 600px !important;
  min-height: 600px !important;
  max-height: 600px !important;
}
.viewNormal {
  width: 646.5px !important;
  min-width: 646.5px !important;
  max-width: 646.5px !important;
  height: 433.5px !important;
  min-height: 433.5px !important;
  max-height: 433.5px !important;
}
.input {
  @apply tw-border tw-border-solid tw-rounded tw-border-gray-400 tw-p-2 tw-outline-none md:tw-w-2/3 tw-w-full;
}
</style>
